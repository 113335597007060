.chakra-modal__content-container{
    padding: 16px !important;
}
.chakra-modal__close-btn {
    border: 3px solid black !important;
    border-radius: 100% !important;
    border-color: #CA000E !important;
    height: 32px !important;
    width: 32px !important;
    z-index: 3;
    position: relative;
    outline: none !important;
    outline-offset: 0px;
    top: 16px !important;
    right: 16px !important;
}

.chakra-modal__close-btn svg {
    color: #CA000E !important;
    font-size: 12px !important;
    font-weight: 700;
}
